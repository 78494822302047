<style scoped></style>

<template>
	<errorContainer :error="erreur" :warning="warning">
		<transition name="slide">
			<div
				class="container-layout details-document-package px-0"
				:class="{
					'container-layout': $screen.width > 576,
					'container-mobile': $screen.width <= 576
				}"
			>
				<div class="form">
					<b-row v-if="TsDays">
						<!-- <b-col md="12">
							<h1 :class="[`${!$screen.sm ? 'main-page-title' : ''}`, { 'is-pwa': $isPwa() }]">
								{{ FormMSG(215, 'Stage Day') }}
							</h1>
						</b-col> -->
						<b-col cols="12" xl="12">
							<transition name="slide">
								<!-- bg-variant="secondary" text-variant="white" -->
								<div>
									<!-- <pre>{{ this.curDay.stageDay }}</pre> -->
									<!--      <h1>{{ this.FormMSG(10,'Stage Days') }}</h1>  -->
									<b-card no-body>
										<!-- bg-secondary text-white -->
										<b-card-body>
											<b-row>
												<b-col cols="12">
													<div class="alert alert-info">{{ this.caption2 }}</div>
													<div class="alert alert-warning" v-if="this.disableSave">
														{{ FormMSG(369, 'You must choose at least one of the options (Use hours, Define mileage to set)') }}
													</div>
												</b-col>
											</b-row>
											<b-row>
												<b-col xl="6" md="6">
													<Calendar
														v-model="attrs[0].dates"
														:max-date="this.nextYear"
														:locale="getCurLangStr"
														:attributes="attrs"
														:columns="1"
														:disabled-dates="[{ start: tomorrow, end: null }]"
														is-expanded
														@dayclick="calDateSelected"
														@update:from-page="calPageUpdated"
													/>
												</b-col>
											</b-row>
											<b-row class="mt-3">
												<b-col xl="6" md="6">
													<b-form-group :label="FormMSG(21, 'Date:')" label-for="date">
														<b-form-input
															disabled
															:value="moment(curDay.stageDay.date).format('DD/MM/YYYY')"
															@input="updateDate"
															type="text"
															id="date"
														/>
													</b-form-group>
												</b-col>
												<hr />
											</b-row>
											<div class="divider-line mt-3 mb-3" />
											<b-row>
												<b-col cols="12">
													<b-row class="mb-2">
														<b-col>
															<b-form-checkbox
																v-model="curDay.stageDay.useHours"
																size="lg"
																id="chkUseHours"
																:value="true"
																:unchecked-value="false"
																class="pj-cb pb-1"
															>
																{{ FormMSG(139, 'Use hours') }}
															</b-form-checkbox>
														</b-col>
													</b-row>
													<!--
														//hide-end-date
														//hide-after -->
													<b-collapse :visible="curDay.stageDay.useHours">
														<fieldset class="scheduler-border border-color-blue-streak">
															<legend class="scheduler-border text-color-blue-streak fs-16">
																{{ FormMSG(268, '1st shooting') }}
															</legend>
															<b-row class="pb-4">
																<b-col xl="2" lg="2" md="12" sm="12">
																	<StartEndTimeOverRange
																		:start-backward-day="1"
																		:start-forward-day="1"
																		:end-backward-day="0"
																		:end-forward-day="0"
																		hide-end-date
																		:value="{
																			start: formatTimeWithStageDayDate(curDay.stageDay.strTime, 'startDateStrTime'),
																			end: endDateFirstShooting,
																			date: getStrDateToDateTime(curDay.stageDay.date)
																		}"
																		:disable-save="disableSave"
																		:start-label="FormMSG(26, 'Start:')"
																		:state-start-date="errorState.strTime"
																		@change-time-start="handleChangeTime($event, 'strTime', 'startDateStrTime')"
																	/>
																</b-col>
																<b-col xl="5" lg="5" md="12" sm="12">
																	<StartEndTimeOverRange
																		:start-backward-day="1"
																		:start-forward-day="0"
																		:end-backward-day="0"
																		:end-forward-day="1"
																		:value="{
																			start: formatTimeWithStageDayDate(
																				curDay.stageDay.lunchStrTime,
																				'startDateLunchStrTime'
																			),
																			end: formatTimeWithStageDayDate(
																				curDay.stageDay.lunchEndTime,
																				'startDateLunchEndTime'
																			),
																			date: getStrDateToDateTime(curDay.stageDay.date)
																		}"
																		:disable-save="disableSave"
																		:start-label="FormMSG(29, 'Lunch Start:')"
																		:end-label="FormMSG(30, 'Lunch End:')"
																		:state-start-date="errorState.lunchStrTime"
																		:state-end-date="errorState.lunchEndTime"
																		@change-range="
																			handleChangeRange(
																				$event,
																				'startDateLunchStrTime',
																				'lunchStrTime',
																				'startDateLunchEndTime',
																				'lunchEndTime'
																			)
																		"
																	/>
																</b-col>
																<b-col xl="5" lg="5" md="12" sm="12">
																	<StartEndTimeOverRange
																		:start-backward-day="1"
																		:start-forward-day="0"
																		:end-backward-day="0"
																		:end-forward-day="1"
																		:value="{
																			start: formatTimeWithStageDayDate(curDay.stageDay.endTime, 'startDateEndTime'),
																			end: formatTimeWithStageDayDate(
																				curDay.stageDay.actualEndTime,
																				'startDateActualEndTime'
																			),
																			date: getStrDateToDateTime(curDay.stageDay.date)
																		}"
																		:disable-save="disableSave"
																		:start-label="FormMSG(127, 'Expected end')"
																		:end-label="FormMSG(128, 'Actual end')"
																		:state-start-date="errorState.endTime"
																		:state-end-date="errorState.actualEndTime"
																		sync-start-end
																		@change-range="
																			handleChangeRange(
																				$event,
																				'startDateEndTime',
																				'endTime',
																				'startDateActualEndTime',
																				'actualEndTime'
																			)
																		"
																	/>
																</b-col>
															</b-row>
														</fieldset>
														<fieldset class="scheduler-border border-color-blue-streak">
															<legend class="scheduler-border text-color-blue-streak fs-16 d-flex align-items-center">
																<b-form-checkbox
																	size="lg"
																	v-model="curDay.stageDay.is2NdShooting"
																	@change="handleChange2NdShooting"
																/>
																<div style="margin-top: 1px">{{ FormMSG(269, '2nd shooting') }}</div>
															</legend>
															<b-row class="pb-4">
																<b-col xl="2" lg="2" md="12" sm="12">
																	<StartEndTimeOverRange
																		:start-backward-day="1"
																		:start-forward-day="1"
																		:end-backward-day="0"
																		:end-forward-day="0"
																		hide-end-date
																		:value="{
																			start: formatTimeWithStageDayDate(curDay.stageDay.strTime2, 'startDateStrTime2'),
																			end: endDateSecondShooting,
																			date: getStrDateToDateTime(curDay.stageDay.date)
																		}"
																		:disable-save="disableSave || !curDay.stageDay.is2NdShooting"
																		:start-label="FormMSG(371, 'Start')"
																		:state-start-date="errorState.strTime2"
																		@change-time-start="handleChangeTime($event, 'strTime2', 'startDateStrTime2')"
																	/>
																</b-col>
																<b-col xl="5" lg="5" md="12" sm="12">
																	<StartEndTimeOverRange
																		:start-backward-day="1"
																		:start-forward-day="0"
																		:end-backward-day="0"
																		:end-forward-day="1"
																		:value="{
																			start: formatTimeWithStageDayDate(
																				curDay.stageDay.lunchStrTime2,
																				'startDateLunchStrTime2'
																			),
																			end: formatTimeWithStageDayDate(
																				curDay.stageDay.lunchEndTime2,
																				'startDateLunchEndTime2'
																			),
																			date: getStrDateToDateTime(curDay.stageDay.date)
																		}"
																		:disable-save="disableSave || !curDay.stageDay.is2NdShooting"
																		:start-label="FormMSG(372, 'Lunch Start')"
																		:end-label="FormMSG(373, 'Lunch End')"
																		:state-start-date="errorState.lunchStrTime2"
																		:state-end-date="errorState.lunchEndTime2"
																		@change-range="
																			handleChangeRange(
																				$event,
																				'startDateLunchStrTime2',
																				'lunchStrTime2',
																				'startDateLunchEndTime2',
																				'lunchEndTime2'
																			)
																		"
																	/>
																</b-col>
																<b-col xl="5" lg="5" md="12" sm="12">
																	<StartEndTimeOverRange
																		:start-backward-day="1"
																		:start-forward-day="0"
																		:end-backward-day="0"
																		:end-forward-day="1"
																		:value="{
																			start: formatTimeWithStageDayDate(curDay.stageDay.endTime2, 'startDateEndTime2'),
																			end: formatTimeWithStageDayDate(
																				curDay.stageDay.actualEndTime2,
																				'startDateActualEndTime2'
																			),
																			date: getStrDateToDateTime(curDay.stageDay.date)
																		}"
																		:disable-save="disableSave || !curDay.stageDay.is2NdShooting"
																		:start-label="FormMSG(374, 'Expected end')"
																		:end-label="FormMSG(375, 'Actual end')"
																		:state-start-date="errorState.endTime2"
																		:state-end-date="errorState.actualEndTime2"
																		sync-start-end
																		@change-range="
																			handleChangeRange(
																				$event,
																				'startDateEndTime2',
																				'endTime2',
																				'startDateActualEndTime2',
																				'actualEndTime2'
																			)
																		"
																	/>
																</b-col>
															</b-row>
														</fieldset>
														<fieldset class="scheduler-border border-color-blue-streak">
															<legend class="scheduler-border text-color-blue-streak fs-16 d-flex align-items-center">
																<b-form-checkbox
																	size="lg"
																	v-model="curDay.stageDay.is3RdShooting"
																	:disabled="!curDay.stageDay.is2NdShooting"
																	@change="handleChange3rdShooting"
																/>
																<div style="margin-top: 1px">{{ FormMSG(370, '3rd shooting') }}</div>
															</legend>
															<b-row class="pb-4">
																<b-col xl="2" lg="2" md="12" sm="12">
																	<StartEndTimeOverRange
																		:start-backward-day="1"
																		:start-forward-day="0"
																		:end-backward-day="0"
																		:end-forward-day="1"
																		hide-end-date
																		hide-after
																		:value="{
																			start: formatTimeWithStageDayDate(curDay.stageDay.strTime3, 'startDateStrTime3'),
																			end: endDateThirdShooting,
																			date: getStrDateToDateTime(curDay.stageDay.date)
																		}"
																		:disable-save="disableSave || !curDay.stageDay.is3RdShooting"
																		:start-label="FormMSG(376, 'Start')"
																		:state-start-date="errorState.strTime3"
																		@change-time-start="handleChangeTime($event, 'strTime3', 'startDateStrTime3')"
																	/>
																</b-col>
																<b-col xl="5" lg="5" md="12" sm="12">
																	<StartEndTimeOverRange
																		:start-backward-day="1"
																		:start-forward-day="0"
																		:end-backward-day="0"
																		:end-forward-day="1"
																		:value="{
																			start: formatTimeWithStageDayDate(
																				curDay.stageDay.lunchStrTime3,
																				'startDateLunchStrTime3'
																			),
																			end: formatTimeWithStageDayDate(
																				curDay.stageDay.lunchEndTime3,
																				'startDateLunchEndTime3'
																			),
																			date: getStrDateToDateTime(curDay.stageDay.date)
																		}"
																		:disable-save="disableSave || !curDay.stageDay.is3RdShooting"
																		:start-label="FormMSG(377, 'Lunch Start')"
																		:end-label="FormMSG(378, 'Lunch End')"
																		:state-start-date="errorState.lunchStrTime3"
																		:state-end-date="errorState.lunchEndTime3"
																		@change-range="
																			handleChangeRange(
																				$event,
																				'startDateLunchStrTime3',
																				'lunchStrTime3',
																				'startDateLunchEndTime3',
																				'lunchEndTime3'
																			)
																		"
																	/>
																</b-col>
																<b-col xl="5" lg="5" md="12" sm="12">
																	<StartEndTimeOverRange
																		:start-backward-day="1"
																		:start-forward-day="0"
																		:end-backward-day="0"
																		:end-forward-day="1"
																		:value="{
																			start: formatTimeWithStageDayDate(curDay.stageDay.endTime3, 'startDateEndTime3'),
																			end: formatTimeWithStageDayDate(
																				curDay.stageDay.actualEndTime3,
																				'startDateActualEndTime3'
																			),
																			date: getStrDateToDateTime(curDay.stageDay.date)
																		}"
																		:disable-save="disableSave || !curDay.stageDay.is3RdShooting"
																		:start-label="FormMSG(379, 'Expected end')"
																		:end-label="FormMSG(380, 'Actual end')"
																		:state-start-date="errorState.endTime3"
																		:state-end-date="errorState.actualEndTime3"
																		sync-start-end
																		@change-range="
																			handleChangeRange(
																				$event,
																				'startDateEndTime3',
																				'endTime3',
																				'startDateActualEndTime3',
																				'actualEndTime3'
																			)
																		"
																	/>
																</b-col>
															</b-row>
														</fieldset>
														<b-row class="mt-3">
															<b-col>
																<b-form-group :label="FormMSG(32, 'Comment:')" label-for="comment">
																	<b-form-textarea
																		:disabled="false"
																		id="comment"
																		v-model="curDay.stageDay.comment"
																		rows="2"
																	></b-form-textarea>
																</b-form-group>
															</b-col>
														</b-row>
													</b-collapse>
												</b-col>
											</b-row>
											<div class="divider-line mt-3 mb-3" />
											<b-row>
												<b-col md="6" lg="6" xl="6">
													<b-row class="mb-3">
														<b-col>
															<b-form-checkbox
																size="lg"
																id="chkMileageToSet"
																v-model="curDay.stageDay.useMileageToSet"
																class="pj-cb pb-1"
																:value="true"
																:unchecked-value="false"
															>
																{{ FormMSG(131, 'Define mileage to set') }}
															</b-form-checkbox>
														</b-col>
													</b-row>
													<b-collapse :visible="curDay.stageDay.useMileageToSet">
														<b-row>
															<b-col>
																<b-form-group :label="FormMSG(132, 'Description')" label-for="description">
																	<b-form-textarea
																		:disabled="false"
																		id="description"
																		v-model="curDay.stageDay.kmToSetDescription"
																		rows="2"
																	/>
																</b-form-group>
															</b-col>
														</b-row>
														<b-row>
															<b-col md="6" lg="6">
																<b-form-group :label="FormMSG(134, 'Mileage to set definition')" label-for="lunchEndTime">
																	<b-form-select
																		v-model="mileageDefinition"
																		:options="optionsMileageDefinition"
																		@change="handleChangeSetDefinition"
																	></b-form-select>
																</b-form-group>
															</b-col>
															<b-col md="5" lg="5" xs="10" sm="10">
																<b-form-group :label="labelInputMileageDefinition">
																	<b-input-group>
																		<b-form-input type="text" v-model="valueMileageDefinition" />
																		<b-input-group-append>
																			<b-input-group-text>
																				<span
																					v-if="curDay.stageDay.kmToSetDefined"
																					class="font-weight-bold"
																					style="color: #06263e; font-size: 1rem"
																				>
																					{{ distanceUnit }}
																				</span>
																				<currency-svg
																					v-if="curDay.stageDay.hoursDefined"
																					color="#06263E"
																					opacity="0.85"
																					width="15"
																					height="15"
																				/>
																			</b-input-group-text>
																		</b-input-group-append>
																	</b-input-group>
																</b-form-group>
															</b-col>
														</b-row>
													</b-collapse>
												</b-col>
											</b-row>
											<b-row>
												<b-col cols="3" offset="9">
													<b-button block @click="curDaySave()" variant="success pull-left" :disabled="disableSave" size="md">
														{{ this.FormMSG(33, 'Save modification') }}
													</b-button>
												</b-col>
											</b-row>
											<b-modal
												header-class="header-class-modal-doc-package"
												:title="FormMSG(34, 'Success!')"
												class="modal-success"
												v-model="successModal"
												@ok="successModal = false"
												ok-variant="success"
												ok-only
											>
												{{ this.FormMSG(35, 'Your information has been saved') }}
											</b-modal>
											<b-modal
												header-class="header-class-modal-doc-package"
												:title="FormMSG(34, 'Success!')"
												class="modal-success"
												v-model="successModalDelete"
												@ok="successModalDelete = false"
												ok-variant="success"
												ok-only
											>
												{{ this.FormMSG(36, 'Your information has been deleted.') }}
											</b-modal>
											<!--     TABLE OF TSDAYS       sort-by="date"    use striped to highlight each even row   @row-selected="rowSelected" -->
										</b-card-body>
									</b-card>
									<b-card no-body>
										<b-card-body>
											<b-row>
												<b-col cols="12" xl="12">
													<h2>{{ this.FormMSG(38, 'Stage days of ') }} &nbsp; {{ getMonthAndYear(this.curDay.stageDay.date) }}</h2>
													<div v-if="getRowCount(this.TsDays) > 0">
														<p>{{ this.FormMSG(37, 'Click on a row to view a day') }}</p>
														<CardListBuilder v-if="$screen.width < 992" :items="TsDaysTable" :fields="tsfields">
															<template slot="actions" slot-scope="data">
																<b-button variant="none" size="sm" @click="rowClicked(data.item)">
																	<component :is="getLucideIcon(ICONS.EDIT.name)" :color="ICONS.EDIT.color" :size="20" />
																</b-button>
																<b-button variant="none" size="sm" @click="delItem(data.item.stageDay.id)">
																	<component :is="getLucideIcon(ICONS.TRASH.name)" :color="ICONS.TRASH.color" :size="20" />
																</b-button>
															</template>
														</CardListBuilder>
														<b-table
															v-if="$screen.width >= 992"
															:hover="hover"
															responsive="sm"
															ref="myTable"
															selectable
															:selectedVariant="selectedColor"
															:select-mode="selectMode"
															:items="TsDaysTable"
															style="text-align: center"
															:fields="tsfields"
															:current-page="currentPage"
															:per-page="perPage"
															@row-clicked="rowClicked"
															:head-variant="hv"
															bordered
															small
															sticky-header="700px"
														>
															<template v-slot:cell(validatedStatus)="data">
																<span v-bind:class="['badge badge-' + data.item.validatedClass]">{{
																	data.item.validatedStatus
																}}</span>
															</template>
															<!-- <template slot="rem" slot-scope="data"> -->
															<template v-slot:cell(rem)="data">
																<b-button variant="none" size="sm" @click="delItem(data.item.stageDay.id)">
																	<component :is="getLucideIcon(ICONS.TRASH.name)" :color="ICONS.TRASH.color" :size="20" />
																</b-button>
																<!-- <v-icon>icon-close</v-icon> -->
															</template>
															<template v-slot:cell(stageDayEndTime)="data">
																<div class="d-flex justify-content-center align-items-center">
																	<div class="adjust-icon-ts-tsd">
																		{{ getLabelDate(data.item, 'endTime', 'startDateEndTime') }}
																	</div>
																</div>
															</template>
															<template v-slot:cell(stageDayActualEndTime)="data">
																<div class="d-flex justify-content-center align-items-center">
																	<div class="adjust-icon-ts-tsd">
																		{{ getLabelDate(data.item, 'actualEndTime', 'startDateActualEndTime') }}
																	</div>
																</div>
															</template>
															<template v-slot:cell(stageDayLunchStrTime)="data">
																<div class="d-flex justify-content-center align-items-center">
																	<div class="adjust-icon-ts-tsd">
																		{{ getLabelDate(data.item, 'lunchStrTime', 'startDateLunchStrTime') }}
																	</div>
																</div>
															</template>
															<template v-slot:cell(stageDayLunchEndTime)="data">
																<div class="d-flex justify-content-center align-items-center">
																	<div class="adjust-icon-ts-tsd">
																		{{ getLabelDate(data.item, 'lunchEndTime', 'startDateLunchEndTime') }}
																	</div>
																</div>
															</template>
														</b-table>
													</div>
													<div v-if="getRowCount(this.TsDays) < 1" class="empty-state">
														{{ this.FormMSG(42, 'No stage days for this month') }}
													</div>
												</b-col>
											</b-row>
										</b-card-body>
									</b-card>
								</div>
							</transition>
						</b-col>
					</b-row>
				</div>
			</div>
		</transition>
	</errorContainer>
</template>

<script>
import Calendar from 'v-calendar/lib/components/calendar.umd';
import languageMessages from '@/mixins/languageMessages';
import globalMixin from '@/mixins/global.mixin';
import { getStageDays, addUpdStageDay, delStageDay } from '@/cruds/timesheet.crud';
import moment from 'moment';
import { rendCurrency } from '~helpers';
import { getLangISOByIndex } from '@/helpers/translate';
import { store } from '@/store';
import _ from 'lodash';
import StartEndTimeOverRange from '@/components/StartEndTimeOverRange';
import CurrencySvg from '@/components/Svg/Currency';

const INIT_DATA_STAGE_DAY = {
	id: 0,
	useMileageToSet: false,
	useHours: true,
	kmToSet: 0,
	kmToSetSalary: 0,
	kmToSetDescription: '',
	hoursDefined: false,
	kmToSetDefined: true,
	actualEndTime: '18:00',
	lunchStrTime: '00:00',
	lunchEndTime: '00:00',
	hours: '',
	minutes: '',
	comment: '',
	strTime: '08:00',
	endTime: '18:00',
	date: '2019-09-01',
	startDateEndTime: '',
	startDateActualEndTime: '',
	startDateStrTime: '',
	startDateLunchStrTime: '',
	startDateLunchEndTime: '',
	is2NdShooting: false,
	is3RdShooting: false,
	strTime2: null,
	endTime2: null,
	actualEndTime2: null,
	lunchStrTime2: null,
	lunchEndTime2: null,
	strTime3: null,
	endTime3: null,
	actualEndTime3: null,
	lunchStrTime3: null,
	lunchEndTime3: null,
	startDateEndTime2: '',
	startDateActualEndTime2: '',
	startDateStrTime2: '',
	startDateLunchStrTime2: '',
	startDateLunchEndTime2: '',
	startDateEndTime3: '',
	startDateActualEndTime3: '',
	startDateStrTime3: '',
	startDateLunchStrTime3: '',
	startDateLunchEndTime3: '',
	totMin2: 0,
	totMin3: 0,
	hours2: '',
	minutes2: '',
	hours3: '',
	minutes3: ''
};

export default {
	name: 'StageDay',
	mixins: [languageMessages, globalMixin],
	props: {
		hover: {
			type: Boolean,
			default: true
		},
		striped: {
			type: Boolean,
			default: true
		},
		bordered: {
			type: Boolean,
			default: true
		},
		small: {
			type: Boolean,
			default: false
		},
		fixed: {
			type: Boolean,
			default: false
		},
		intialDate: ['2021-05-05']
	},
	components: {
		Calendar,
		StartEndTimeOverRange,
		CurrencySvg
	},
	data() {
		return {
			hv: 'dark',
			Validated: 0,
			erreur: {},
			curMonthStr: `${moment().format('YYYY')}-${moment().format('MM')}`,
			TsDays: [],
			TsDaysTable: [],
			currentPage: 1,
			perPage: 0, // anciennement 31, 0 pour retrait de pagination
			warning: '',
			successModal: false,
			successModalDelete: false,
			filter: '',
			timeZoneStr: '',
			timeZoneHourDif: 0,
			selectedColor: 'primary',
			selectMode: 'single',
			rowSelected: -1,
			curDay: {
				id: 0,
				date: '',
				strTime: 'T08:00:00Z',
				endTime: 'T18:00:00Z',
				totMin: 0,
				lunchStrTime: 'T00:00:00Z',
				lunchEndTime: 'T00:00:00Z',
				hours: '',
				minutes: '',
				hhmm: '',
				comment: '',
				stageDay: {
					..._.cloneDeep(INIT_DATA_STAGE_DAY)
				},
				strTime2: 'T00:00:00Z',
				endTime2: 'T00:00:00Z',
				lunchStrTime2: 'T00:00:00Z',
				lunchEndTime2: 'T00:00:00Z',
				totMin2: 0,
				strTime3: 'T00:00:00Z',
				endTime3: 'T00:00:00Z',
				lunchStrTime3: 'T00:00:00Z',
				lunchEndTime3: 'T00:00:00Z',
				totMin3: 0
			},
			newCurDay: {
				id: 0,
				date: '2019-09-01',
				strTime: 'T08:00:00Z',
				endTime: 'T18:00:00Z',
				totMin: 0,
				lunchStrTime: 'T00:00:00Z',
				lunchEndTime: 'T00:00:00Z',
				hours: '',
				minutes: '',
				hhmm: '',
				comment: '',
				stageDay: {
					..._.cloneDeep(INIT_DATA_STAGE_DAY)
				},
				totMin2: 0,
				totMin3: 0
			},
			valueMileageDefinition: 0,
			firstLoadScreen: true,
			errorState: {
				endTime: null,
				actualEndTime: null,
				lunchStrTime: null,
				lunchEndTime: null,
				endTime2: null,
				actualEndTime2: null,
				lunchStrTime2: null,
				lunchEndTime2: null,
				endTime3: null,
				actualEndTime3: null,
				lunchStrTime3: null,
				lunchEndTime3: null
			},

			endDateFirstShooting: moment().format(),
			endDateSecondShooting: moment().format(),
			endDateThirdShooting: moment().format()
		};
	},
	watch: {
		valueMileageDefinition: {
			handler(newVal) {
				if (this.curDay.stageDay.kmToSetDefined) {
					this.curDay.stageDay.kmToSet = parseFloat(newVal, 10);
				}
				if (this.curDay.stageDay.hoursDefined) {
					this.curDay.stageDay.kmToSetSalary = parseFloat(newVal, 10);
				}
			}
		}
	},
	computed: {
		getCurLangStr() {
			return getLangISOByIndex(store.appLanguage()).two;
		},
		labelInputMileageDefinition() {
			if (this.curDay.stageDay.kmToSetDefined) {
				return this.FormMSG(135, 'Distance to set');
			}
			if (this.curDay.stageDay.hoursDefined) {
				return this.FormMSG(165, 'Amount for mileage to set');
			}
		},
		mileageDefinition: {
			get() {
				if (this.curDay.stageDay.kmToSetDefined) {
					return 'use_distance';
				}
				if (this.curDay.stageDay.hoursDefined) {
					return 'use_fix_amount';
				}
				if (!this.curDay.stageDay.kmToSetDefined && !this.curDay.stageDay.hoursDefined) {
					return 'use_distance';
				}
			},
			set(val) {
				if (val === 'use_distance') {
					this.curDay.stageDay.kmToSetDefined = true;
					this.curDay.stageDay.hoursDefined = false;
					//this.valueMileageDefinition = 0;
				} else if (val === 'use_fix_amount') {
					this.curDay.stageDay.kmToSetDefined = false;
					this.curDay.stageDay.hoursDefined = true;
					//this.valueMileageDefinition = 0;
				}
			}
		},
		optionsMileageDefinition() {
			return [
				{ value: 'use_distance', text: this.FormMSG(156, 'Use distance') },
				{ value: 'use_fix_amount', text: this.FormMSG(157, 'Use fix amount') }
			];
		},
		caption2() {
			return this.FormMSG(13, 'Enter the working hours for every day');
		},
		tsfields() {
			return [
				{
					key: 'stageDay.date',
					label: this.FormMSG(51, 'Date'),
					formatter: (value) => {
						// const val = new Date(value.split('T')[0]);
						return moment(value).format('DD/MM/YYYY');
					},
					sortable: true
				},
				{
					key: 'hhmm',
					label: this.FormMSG(381, 'Hours 1'),
					sortable: false
				},
				{
					key: 'stageDay.strTime',
					label: this.FormMSG(382, 'Start 1'),
					formatter: (value) => {
						if (_.isNil(value)) {
							return '-';
						}
						return value;
					},
					sortable: false
				},
				{
					key: 'stageDayExpectedActualTime',
					label: this.FormMSG(383, 'Expected / Actual end 1'),
					formatter: (value) => {
						return value.replace(new RegExp('null', 'g'), '--:--');
					},
					sortable: false
				},
				{
					key: 'stageDayLunchStartEndTime',
					label: this.FormMSG(384, 'Lunch 1'),
					formatter: (value) => {
						return value.replace(new RegExp('null', 'g'), '--:--');
					},
					sortable: false
				},
				{
					key: 'hhmm2',
					label: this.FormMSG(385, 'Hours 2'),
					formatter: (value) => {
						return value.replace(new RegExp('null', 'g'), '--:--');
					},
					sortable: false
				},
				{
					key: 'stageDay.strTime2',
					label: this.FormMSG(386, 'Start 2'),
					formatter: (value) => {
						if (_.isNil(value)) {
							return '-';
						}

						return value.replace(new RegExp('null', 'g'), '--:--');
					},
					sortable: false
				},
				{
					key: 'stageDayExpectedActualTime2',
					label: this.FormMSG(387, 'Expected / Actual end 2'),
					formatter: (value) => {
						return value.replace(new RegExp('null', 'g'), '--:--');
					},
					sortable: false
				},
				{
					key: 'stageDayLunchStartEndTime2',
					label: this.FormMSG(388, 'Lunch 2'),
					formatter: (value) => {
						return value.replace(new RegExp('null', 'g'), '--:--');
					},
					sortable: false
				},
				{
					key: 'hhmm3',
					label: this.FormMSG(389, 'Hours 2'),
					formatter: (value) => {
						return value.replace(new RegExp('null', 'g'), '--:--');
					},
					sortable: false
				},
				{
					key: 'stageDay.strTime3',
					label: this.FormMSG(390, 'Start 3'),
					formatter: (value) => {
						if (_.isNil(value)) {
							return '-';
						}
						return value.replace(new RegExp('null', 'g'), '--:--');
					},
					sortable: false
				},
				{
					key: 'stageDayExpectedActualTime3',
					label: this.FormMSG(391, 'Expected / Actual end 3'),
					formatter: (value) => {
						return value.replace(new RegExp('null', 'g'), '--:--');
					},
					sortable: false
				},
				{
					key: 'stageDayLunchStartEndTime3',
					label: this.FormMSG(392, 'Lunch 3'),
					formatter: (value) => {
						return value.replace(new RegExp('null', 'g'), '--:--');
					},
					sortable: false
				},
				{
					key: 'mileageToSet',
					label: this.FormMSG(158, 'Mileage to set'),
					formatter: (value, key, item) => {
						if (item.stageDay.kmToSetDefined) {
							return `${item.stageDay.kmToSet} Km`;
						}
						if (item.stageDay.hoursDefined) {
							return rendCurrency(item.stageDay.kmToSetSalary);
						}
					},
					sortable: false
				},
				{
					key: 'rem',
					label: this.FormMSG(64, 'Remove'),
					sortable: false,
					formatter: (v) => v
				}
			];
		},
		attrs() {
			// display items in the calendar
			const curDayDate = moment(this.curDay.stageDay.date).format('YYYY-MM-DD');

			var ar = [
				{
					key: 'notSubmitted',
					dot: {
						color: 'pink'
					},
					dates: []
				},
				{
					key: 'submitted',
					dot: {
						color: 'blue'
					},
					dates: []
				},
				{
					key: 'validatedHOD',
					dot: {
						color: 'yellow'
					},
					dates: []
				},
				{
					key: 'refused',
					dot: {
						color: 'red'
					},
					dates: []
				},
				{
					key: 'validated',
					dot: {
						color: 'green'
					},
					dates: []
				},
				{
					key: 'currentDay',
					highlight: 'red',
					dates: new Date(curDayDate)
				}
			];

			for (let i in this.TsDays) {
				//const da = new Date(moment(this.TsDays[i].stageDay.date).format('YYYY-MM-DD'));
				const da = moment(this.TsDays[i].stageDay.date).format('YYYY-MM-DD');

				ar[1].dates.push(da);
			}

			return ar;
		},
		nextYear() {
			let today = new Date();
			let tmw = new Date(today.getTime() + 365 * 24 * 60 * 60 * 1000);
			return tmw;
		},
		tomorrow() {
			var today = new Date();
			// var tmw = new Date(today.getTime() + 48 * 60 * 60 * 1000);
			let tmw = new Date(moment().add(13, 'days'));
			return tmw;
		},
		disableSave() {
			if (!this.curDay.stageDay.useHours && !this.curDay.stageDay.useMileageToSet) {
				return true;
			}
			return false;
		},
		styleObject: function () {
			var myObj = {
				color: 'purple',
				fontSize: '14px'
			};
			myObj.color = 'blue';

			return myObj;
		},
		distanceUnit() {
			return store.state.myProfile.distanceUnit;
		}
	},
	methods: {
		handleChange3rdShooting(value) {
			if (value === false) {
				this.curDay.stageDay.strTime3 = null;
				this.curDay.stageDay.endTime3 = null;
				this.curDay.stageDay.actualEndTime3 = null;
				this.curDay.stageDay.lunchStrTime3 = null;
				this.curDay.stageDay.lunchEndTime3 = null;
				this.curDay.stageDay.startDateEndTime3 = '';
				this.curDay.stageDay.startDateActualEndTime3 = '';
				this.curDay.stageDay.startDateStrTime3 = '';
				this.curDay.stageDay.startDateLunchStrTime3 = '';
				this.curDay.stageDay.startDateLunchEndTime3 = '';
			} else {
				this.curDay.stageDay.strTime3 = this.curDay.stageDay.strTime;
				this.curDay.stageDay.endTime3 = this.curDay.stageDay.endTime;
				this.curDay.stageDay.actualEndTime3 = this.curDay.stageDay.actualEndTime;
				this.curDay.stageDay.lunchStrTime3 = this.curDay.stageDay.lunchStrTime;
				this.curDay.stageDay.lunchEndTime3 = this.curDay.stageDay.lunchEndTime;
				this.curDay.stageDay.startDateEndTime3 = this.curDay.stageDay.startDateEndTime2;
				this.curDay.stageDay.startDateActualEndTime3 = this.curDay.stageDay.startDateActualEndTime2;
				this.curDay.stageDay.startDateStrTime3 = this.curDay.stageDay.startDateStrTime2;
				this.curDay.stageDay.startDateLunchStrTime3 = this.curDay.stageDay.startDateLunchStrTime2;
				this.curDay.stageDay.startDateLunchEndTime3 = this.curDay.stageDay.startDateLunchEndTime2;
			}
		},
		handleChange2NdShooting(value) {
			if (value === false) {
				this.curDay.stageDay.strTime2 = null;
				this.curDay.stageDay.endTime2 = null;
				this.curDay.stageDay.actualEndTime2 = null;
				this.curDay.stageDay.lunchStrTime2 = null;
				this.curDay.stageDay.lunchEndTime2 = null;
				this.curDay.stageDay.strTime3 = null;
				this.curDay.stageDay.endTime3 = null;
				this.curDay.stageDay.actualEndTime3 = null;
				this.curDay.stageDay.lunchStrTime3 = null;
				this.curDay.stageDay.lunchEndTime3 = null;
				this.curDay.stageDay.startDateEndTime2 = '';
				this.curDay.stageDay.startDateActualEndTime2 = '';
				this.curDay.stageDay.startDateStrTime2 = '';
				this.curDay.stageDay.startDateLunchStrTime2 = '';
				this.curDay.stageDay.startDateLunchEndTime2 = '';
				this.curDay.stageDay.startDateEndTime3 = '';
				this.curDay.stageDay.startDateActualEndTime3 = '';
				this.curDay.stageDay.startDateStrTime3 = '';
				this.curDay.stageDay.startDateLunchStrTime3 = '';
				this.curDay.stageDay.startDateLunchEndTime3 = '';
			} else {
				this.curDay.stageDay.strTime2 = this.curDay.stageDay.strTime;
				this.curDay.stageDay.endTime2 = this.curDay.stageDay.endTime;
				this.curDay.stageDay.actualEndTime2 = this.curDay.stageDay.actualEndTime;
				this.curDay.stageDay.lunchStrTime2 = this.curDay.stageDay.lunchStrTime;
				this.curDay.stageDay.lunchEndTime2 = this.curDay.stageDay.lunchEndTime;
				this.curDay.stageDay.startDateEndTime2 = this.curDay.stageDay.startDateEndTime;
				this.curDay.stageDay.startDateActualEndTime2 = this.curDay.stageDay.startDateActualEndTime;
				this.curDay.stageDay.startDateStrTime2 = this.curDay.stageDay.startDateStrTime;
				this.curDay.stageDay.startDateLunchStrTime2 = this.curDay.stageDay.startDateLunchStrTime;
				this.curDay.stageDay.startDateLunchEndTime2 = this.curDay.stageDay.startDateLunchEndTime;
			}
		},
		checkDateWitCurDayDate(item, field) {
			const CURDATE = item.stageDay.date.split('T')[0];
			const FIELD_DATE = item.stageDay[field].split('T')[0];

			return CURDATE === FIELD_DATE;
		},
		getLabelDate(item, fieldDate, fieldStartDate) {
			if (!this.checkDateWitCurDayDate(item, fieldStartDate)) {
				return item.stageDay[fieldDate] + ' (' + item.stageDay[fieldStartDate].substring(8, 10) + ')';
			}

			return item.stageDay[fieldDate];
		},
		handleChangeRangeStartFirstShooting(newTimes) {
			let strTime = moment(newTimes[0]).utc().format();
			let endTime = moment(newTimes[1]).utc().format();

			this.curDay.stageDay.startDateStrTime = moment(strTime).format('YYYY-MM-DD');
			this.curDay.stageDay.strTime = moment(strTime).format('HH:mm');

			// this.endDateFirstShooting = moment(`${moment(endTime).format('YYYY-MM-DD')} ${moment(strTime).format('HH:mm')}`).format();
			// this.curDay.stageDay.strTime = moment(strTime).format('HH:mm');
		},
		handleChangeRange(newTimes, dateFieldStart, dataFieldStart, dateFieldEnd, dataFieldEnd) {
			if (this.curDay.stageDay.is2NdShooting === false && dataFieldStart === 'lunchStrTime2' && dataFieldEnd === 'lunchEndTime2') {
				return;
			}
			if (this.curDay.stageDay.is2NdShooting === false && dataFieldStart === 'endTime2' && dataFieldEnd === 'actualEndTime2') {
				return;
			}
			if (this.curDay.stageDay.is3RdShooting === false && dataFieldStart === 'lunchStrTime3' && dataFieldEnd === 'lunchEndTime3') {
				return;
			}
			if (this.curDay.stageDay.is3RdShooting === false && dataFieldStart === 'endTime3' && dataFieldEnd === 'actualEndTime3') {
				return;
			}

			let strTime = moment(newTimes[0]).utc().format();
			let endTime = moment(newTimes[1]).utc().format();

			this.curDay.stageDay[dateFieldStart] = moment(strTime).format('YYYY-MM-DD');
			this.curDay.stageDay[dataFieldStart] = moment(strTime).format('HH:mm');

			this.curDay.stageDay[dateFieldEnd] = moment(endTime).format('YYYY-MM-DD');
			this.curDay.stageDay[dataFieldEnd] = moment(endTime).format('HH:mm');
		},
		handleChangeTime(payload, dataField, dateField) {
			this.curDay.stageDay[dateField] = moment(payload).format('YYYY-MM-DD');
			this.curDay.stageDay[dataField] = moment(payload).format('HH:mm');
		},
		getStrDateToDateTime(value) {
			const curDayDate = value.split('T')[0];
			//return new Date(curDayDate).toISOString();
			return moment(curDayDate).format();
		},
		formatTimeWithStageDayDate(value, fieldDate) {
			if (!_.isNil(value)) {
				//console.log('fieldDate:', fieldDate);
				//console.log(' this.curDay.stageDay[fieldDate]', this.curDay.stageDay[fieldDate]);
				if (_.isNil(this.curDay.stageDay[fieldDate])) {
					let result = moment().format();
					return result;
				}
				const curDayDate = this.curDay.stageDay[fieldDate].split('T')[0];
				//let result = moment(new Date(`${curDayDate} ${value}`)).format();
				let result = moment(`${curDayDate} ${value}`).format();
				/*
				if (fieldDate == 'startDateEndTime2') {
					var tmp = moment(`${curDayDate} ${value}`).format();
					console.log('value:', value);
					console.log('tmp:', tmp);
					console.log('curDayDate:', curDayDate);
					console.log('result:', result);
				}
*/
				return result;
			}

			let result = '0001-01-01T00:00:00Z';
			return result;
		},
		formatTime(date) {
			return moment(date).format('HH:mm');
		},
		clickRows(rowIndex) {
			let myTable = this.$refs.myTable.$el,
				tableBody = myTable.getElementsByTagName('tbody')[0],
				tableRows = tableBody.getElementsByTagName('tr');

			tableRows[rowIndex].click();
			//console.log("tableRows:", tableRows[rowIndex]);
		},
		calDateSelected(item) {
			// console.log('calDateSelected:', item);
			// look for item in the list to select
			const daySelected = item.id;
			//console.log("daySelected:", daySelected);
			let j = -1;
			let i;

			this.resetVariableMileageToSet();

			for (i = 0; i < this.TsDays.length; i++) {
				//console.log("this.TsDays[i].date:", this.TsDays[i].date.substring(0, 10));

				if (this.TsDays[i].stageDay.date.substring(0, 10) === daySelected) {
					j = i;
					break;
				}
			}
			if (j === -1) {
				if (this.rowSelected >= 0) {
					// not an existing day need to unselect the previous selection
					this.clickRows(this.rowSelected);
					this.rowSelected = -1;
				}
			} else if (this.rowSelected !== j) {
				this.rowSelected = j;
				this.clickRows(j);
			}

			this.updateDate(item.id);
		},
		calPageUpdated(item) {
			let mon = parseInt(item.month, 10);
			if (mon < 10) {
				mon = '0' + mon;
			}
			var str = parseInt(item.year, 10) + '-' + mon + '-' + '01';
			// console.log("calPageUpdated:", str);
			this.updateDate(str);
		},
		getCurrentDate() {
			var currentDateWithFormat = new Date().toJSON().slice(0, 10);
			return currentDateWithFormat;
		},
		firstTimeInitialisation() {
			this.setTimeZoneStr();
			this.curDay.stageDay.date = this.getCurrentDate();
			// 1
			this.curDay.stageDay.startDateEndTime = this.getCurrentDate();
			this.curDay.stageDay.startDateActualEndTime = this.getCurrentDate();
			this.curDay.stageDay.startDateStrTime = this.getCurrentDate();
			this.curDay.stageDay.startDateLunchStrTime = this.getCurrentDate();
			this.curDay.stageDay.startDateLunchEndTime = this.getCurrentDate();
			this.curMonthStr = this.curDay.stageDay.date.substring(0, 7);
			// 2
			this.curDay.stageDay.startDateEndTime2 = this.getCurrentDate();
			this.curDay.stageDay.startDateActualEndTime2 = this.getCurrentDate();
			this.curDay.stageDay.startDateStrTime2 = this.getCurrentDate();
			this.curDay.stageDay.startDateLunchStrTime2 = this.getCurrentDate();
			this.curDay.stageDay.startDateLunchEndTime2 = this.getCurrentDate();
			// 3
			this.curDay.stageDay.startDateEndTime3 = this.getCurrentDate();
			this.curDay.stageDay.startDateActualEndTime3 = this.getCurrentDate();
			this.curDay.stageDay.startDateStrTime3 = this.getCurrentDate();
			this.curDay.stageDay.startDateLunchStrTime3 = this.getCurrentDate();
			this.curDay.stageDay.startDateLunchEndTime3 = this.getCurrentDate();
		},
		updateTimeToTZ(timeString) {
			return timeString;
			this.setTimeZoneStr();
			if (timeString.length == 0) {
				return timeString;
			}
			// check if already converted
			//  console.log("in updateTimeToTZ  RECEIVING:",timeString );
			//console.log("substring 9=",timeString.substring(9) );
			//console.log("in updateTimeToTZ timeZoneStr=",this.timeZoneStr);

			if (timeString.substring(9) != this.timeZoneStr) {
				// needs conversion
				//console.log("in updateTimeToTZ inval=",timeString)
				// extract time value
				var timStr = timeString.substring(1, 3);
				var hGmt = parseInt(timStr, 10);
				var h = (hGmt + this.timeZoneHourDif) % 24;
				if (h > 9) {
					var retval = 'T' + h + timeString.substring(3);
				} else {
					var retval = 'T0' + h + timeString.substring(3);
				}
				//    console.log("in updateTimeToTZ retval=",retval);
				retval = retval.substring(0, 9) + this.timeZoneStr;
				//    console.log("in updateTimeToTZ retval new TZ=",retval);
				return retval;
			} else {
				// does not need conversion
				var retval = timeString;
				//    console.log("in updateTimeToTZ retval kept existing conversion=",retval);
				return retval;
			}
		},
		updateTZdataEach(timeData) {
			// setting row color
			//console.log("timeData.endTime:",timeData);
			// https://bootstrap-vue.js.org/docs/reference/color-variants
			timeData._rowVariant = ''; //this.validatedColor(timeData.validated);
			timeData.validatedClass = this.validatedColor(timeData.validated);
			//  console.log("timeData.endTime:",timeData.endTime);
			// 1
			if (!this.initDateTime(timeData.stageDay, 'strTime')) {
				timeData.stageDay.startDateStrTime = moment(timeData.stageDay.strTime).format('YYYY-MM-DD');
				timeData.stageDay.strTime = moment(timeData.stageDay.strTime).format('HH:mm');
			} else {
				timeData.stageDay.startDateStrTime = moment(timeData.stageDay.date).format('YYYY-MM-DD');
			}

			if (!this.initDateTime(timeData.stageDay, 'endTime')) {
				timeData.stageDay.startDateEndTime = moment(timeData.stageDay.endTime).format('YYYY-MM-DD');
				timeData.stageDay.endTime = moment(timeData.stageDay.endTime).format('HH:mm');
			} else {
				timeData.stageDay.startDateEndTime = moment(timeData.stageDay.date).format('YYYY-MM-DD');
			}

			//  console.log("timeData.endTime:",timeData.endTime);
			// check if needs to convert lunch
			if (!this.initDateTime(timeData.stageDay, 'lunchStrTime')) {
				timeData.stageDay.startDateLunchStrTime = moment(timeData.stageDay.lunchStrTime).format('YYYY-MM-DD');
				timeData.stageDay.lunchStrTime = moment(timeData.stageDay.lunchStrTime).format('HH:mm');
			} else {
				timeData.stageDay.startDateLunchStrTime = moment(timeData.stageDay.date).format('YYYY-MM-DD');
			}

			if (!this.initDateTime(timeData.stageDay, 'lunchEndTime')) {
				timeData.stageDay.startDateLunchEndTime = moment(timeData.stageDay.lunchEndTime).format('YYYY-MM-DD');
				timeData.stageDay.lunchEndTime = moment(timeData.stageDay.lunchEndTime).format('HH:mm');
			} else {
				timeData.stageDay.startDateLunchEndTime = moment(timeData.stageDay.date).format('YYYY-MM-DD');
			}

			timeData.stageDayLunchStartEndTime = timeData.stageDay.lunchStrTime + ' - ' + timeData.stageDay.lunchEndTime;

			if (!this.initDateTime(timeData.stageDay, 'actualEndTime')) {
				timeData.stageDay.startDateActualEndTime = moment(timeData.stageDay.actualEndTime).format('YYYY-MM-DD');
				timeData.stageDay.actualEndTime = moment(timeData.stageDay.actualEndTime).format('HH:mm');
			} else {
				timeData.stageDay.startDateActualEndTime = moment(timeData.stageDay.date).format('YYYY-MM-DD');
			}

			timeData.hhmm = timeData.stageDay.hours + ':' + timeData.stageDay.minutes;
			timeData.stageDayExpectedActualTime = timeData.stageDay.endTime + ' / ' + timeData.stageDay.actualEndTime;

			// 2
			if (!this.initDateTime(timeData.stageDay, 'strTime2')) {
				timeData.stageDay.startDateStrTime2 = moment(timeData.stageDay.strTime2).format('YYYY-MM-DD');
				timeData.stageDay.strTime2 = moment(timeData.stageDay.strTime2).format('HH:mm');
			} else {
				timeData.stageDay.startDateStrTime2 = moment(timeData.stageDay.date).format('YYYY-MM-DD');
			}

			if (!this.initDateTime(timeData.stageDay, 'endTime2')) {
				timeData.stageDay.startDateEndTime2 = moment(timeData.stageDay.endTime2).format('YYYY-MM-DD');
				timeData.stageDay.endTime2 = moment(timeData.stageDay.endTime2).format('HH:mm');
			} else {
				timeData.stageDay.startDateEndTime2 = moment(timeData.stageDay.date).format('YYYY-MM-DD');
			}

			//  console.log("timeData.endTime:",timeData.endTime);
			// check if needs to convert lunch
			if (!this.initDateTime(timeData.stageDay, 'lunchStrTime2')) {
				timeData.stageDay.startDateLunchStrTime2 = moment(timeData.stageDay.lunchStrTime2).format('YYYY-MM-DD');
				timeData.stageDay.lunchStrTime2 = moment(timeData.stageDay.lunchStrTime2).format('HH:mm');
			} else {
				timeData.stageDay.startDateLunchStrTime2 = moment(timeData.stageDay.date).format('YYYY-MM-DD');
			}

			if (!this.initDateTime(timeData.stageDay, 'lunchEndTime2')) {
				timeData.stageDay.startDateLunchEndTime2 = moment(timeData.stageDay.lunchEndTime2).format('YYYY-MM-DD');
				timeData.stageDay.lunchEndTime2 = moment(timeData.stageDay.lunchEndTime2).format('HH:mm');
			} else {
				timeData.stageDay.startDateLunchEndTime2 = moment(timeData.stageDay.date).format('YYYY-MM-DD');
			}

			timeData.stageDayLunchStartEndTime2 = timeData.stageDay.lunchStrTime2 + ' - ' + timeData.stageDay.lunchEndTime2;

			if (!this.initDateTime(timeData.stageDay, 'actualEndTime2')) {
				timeData.stageDay.startDateActualEndTime2 = moment(timeData.stageDay.actualEndTime2).format('YYYY-MM-DD');
				timeData.stageDay.actualEndTime2 = moment(timeData.stageDay.actualEndTime2).format('HH:mm');
			} else {
				timeData.stageDay.startDateActualEndTime2 = moment(timeData.stageDay.date).format('YYYY-MM-DD');
			}

			timeData.hhmm2 = timeData.stageDay.hours2 + ':' + timeData.stageDay.minutes2;
			timeData.stageDayExpectedActualTime2 = timeData.stageDay.endTime2 + ' / ' + timeData.stageDay.actualEndTime2;

			// 3
			if (!this.initDateTime(timeData.stageDay, 'strTime3')) {
				timeData.stageDay.startDateStrTime3 = moment(timeData.stageDay.strTime3).format('YYYY-MM-DD');
				timeData.stageDay.strTime3 = moment(timeData.stageDay.strTime3).format('HH:mm');
			} else {
				timeData.stageDay.startDateStrTime3 = moment(timeData.stageDay.date).format('YYYY-MM-DD');
			}

			if (!this.initDateTime(timeData.stageDay, 'endTime3')) {
				timeData.stageDay.startDateEndTime3 = moment(timeData.stageDay.endTime3).format('YYYY-MM-DD');
				timeData.stageDay.endTime3 = moment(timeData.stageDay.endTime3).format('HH:mm');
			} else {
				timeData.stageDay.startDateEndTime3 = moment(timeData.stageDay.date).format('YYYY-MM-DD');
			}

			//  console.log("timeData.endTime:",timeData.endTime);
			// check if needs to convert lunch
			if (!this.initDateTime(timeData.stageDay, 'lunchStrTime3')) {
				timeData.stageDay.startDateLunchStrTime3 = moment(timeData.stageDay.lunchStrTime3).format('YYYY-MM-DD');
				timeData.stageDay.lunchStrTime3 = moment(timeData.stageDay.lunchStrTime3).format('HH:mm');
			} else {
				timeData.stageDay.startDateLunchStrTime3 = moment(timeData.stageDay.date).format('YYYY-MM-DD');
			}

			if (!this.initDateTime(timeData.stageDay, 'lunchEndTime3')) {
				timeData.stageDay.startDateLunchEndTime3 = moment(timeData.stageDay.lunchEndTime3).format('YYYY-MM-DD');
				timeData.stageDay.lunchEndTime3 = moment(timeData.stageDay.lunchEndTime3).format('HH:mm');
			} else {
				timeData.stageDay.startDateLunchEndTime3 = moment(timeData.stageDay.date).format('YYYY-MM-DD');
			}

			timeData.stageDayLunchStartEndTime3 = timeData.stageDay.lunchStrTime3 + ' - ' + timeData.stageDay.lunchEndTime3;

			if (!this.initDateTime(timeData.stageDay, 'actualEndTime3')) {
				timeData.stageDay.startDateActualEndTime3 = moment(timeData.stageDay.actualEndTime3).format('YYYY-MM-DD');
				timeData.stageDay.actualEndTime3 = moment(timeData.stageDay.actualEndTime3).format('HH:mm');
			} else {
				timeData.stageDay.startDateActualEndTime3 = moment(timeData.stageDay.date).format('YYYY-MM-DD');
			}

			timeData.hhmm3 = timeData.stageDay.hours3 + ':' + timeData.stageDay.minutes3;
			timeData.stageDayExpectedActualTime3 = timeData.stageDay.endTime3 + ' / ' + timeData.stageDay.actualEndTime3;
		},
		setTimeZoneStr() {
			// getting timezone
			if (this.timeZoneStr.length > 0) {
				return;
			}
			var d = new Date();
			var n = d.getTimezoneOffset();
			if (n < 0) {
				var hOffset = -n / 60;
				var sign = '+';
			} else {
				var hOffset = n / 60;
				var sign = '-';
			}
			var BASE = '00';
			this.timeZoneStr = hOffset ? sign + BASE.substr(0, 2 - Math.ceil(hOffset / 10)) + hOffset : sign + BASE;
			this.timeZoneStr = this.timeZoneStr + ':00';
			this.timeZoneHourDif = hOffset;
		},
		async curDaySave() {
			try {
				this.setTimeZoneStr();

				this.curDay.stageDay.date = this.curDay.stageDay.date.split('T')[0];
				this.curDay.stageDay.date = this.curDay.stageDay.date.substring(0, 10);
				// 1
				let date = null;
				if (!_.isNil(this.curDay.stageDay.date)) {
					//strTime = new Date(`${this.curDay.stageDay.startDateStrTime} ${this.curDay.stageDay.strTime}`);
					date = moment(`${this.curDay.stageDay.date}`).format();
				}
				// 1
				let strTime = null;
				if (!_.isNil(this.curDay.stageDay.strTime)) {
					//strTime = new Date(`${this.curDay.stageDay.startDateStrTime} ${this.curDay.stageDay.strTime}`);
					strTime = moment(`${this.curDay.stageDay.startDateStrTime} ${this.curDay.stageDay.strTime}`).format();
				}

				let endTime = null;
				if (!_.isNil(this.curDay.stageDay.endTime)) {
					//endTime = new Date(`${this.curDay.stageDay.startDateEndTime} ${this.curDay.stageDay.endTime}`);
					endTime = moment(`${this.curDay.stageDay.startDateEndTime} ${this.curDay.stageDay.endTime}`).format();
				}

				let actualEndTime = null;
				if (!_.isNil(this.curDay.stageDay.actualEndTime)) {
					actualEndTime = moment(`${this.curDay.stageDay.startDateActualEndTime} ${this.curDay.stageDay.actualEndTime}`).format();
				}

				let lunchStrTime = null;
				if (!_.isNil(this.curDay.stageDay.lunchStrTime)) {
					lunchStrTime = moment(`${this.curDay.stageDay.startDateLunchStrTime} ${this.curDay.stageDay.lunchStrTime}`).format();
				}

				let lunchEndTime = null;
				if (!_.isNil(this.curDay.stageDay.lunchEndTime)) {
					lunchEndTime = moment(`${this.curDay.stageDay.startDateLunchEndTime} ${this.curDay.stageDay.lunchEndTime}`).format();
				}

				//2
				let strTime2 = null;
				if (!_.isNil(this.curDay.stageDay.strTime2)) {
					strTime2 = moment(`${this.curDay.stageDay.startDateStrTime2} ${this.curDay.stageDay.strTime2}`).format();
				}

				let endTime2 = null;
				if (!_.isNil(this.curDay.stageDay.endTime2)) {
					endTime2 = moment(`${this.curDay.stageDay.startDateEndTime2} ${this.curDay.stageDay.endTime2}`).format();
				}

				let actualEndTime2 = null;
				if (!_.isNil(this.curDay.stageDay.actualEndTime2)) {
					actualEndTime2 = moment(`${this.curDay.stageDay.startDateActualEndTime2} ${this.curDay.stageDay.actualEndTime2}`).format();
				}

				let lunchStrTime2 = null;
				if (!_.isNil(this.curDay.stageDay.lunchStrTime2)) {
					lunchStrTime2 = moment(`${this.curDay.stageDay.startDateLunchStrTime2} ${this.curDay.stageDay.lunchStrTime2}`).format();
				}

				let lunchEndTime2 = null;
				if (!_.isNil(this.curDay.stageDay.lunchEndTime2)) {
					lunchEndTime2 = moment(`${this.curDay.stageDay.startDateLunchEndTime2} ${this.curDay.stageDay.lunchEndTime2}`).format();
				}

				//3
				let strTime3 = null;
				if (!_.isNil(this.curDay.stageDay.strTime3)) {
					strTime3 = moment(`${this.curDay.stageDay.startDateStrTime3} ${this.curDay.stageDay.strTime3}`).format();
				}

				let endTime3 = null;
				if (!_.isNil(this.curDay.stageDay.endTime3)) {
					endTime3 = moment(`${this.curDay.stageDay.startDateEndTime3} ${this.curDay.stageDay.endTime3}`).format();
				}

				let actualEndTime3 = null;
				if (!_.isNil(this.curDay.stageDay.actualEndTime3)) {
					actualEndTime3 = moment(`${this.curDay.stageDay.startDateActualEndTime3} ${this.curDay.stageDay.actualEndTime3}`).format();
				}

				let lunchStrTime3 = null;
				if (!_.isNil(this.curDay.stageDay.lunchStrTime3)) {
					lunchStrTime3 = moment(`${this.curDay.stageDay.startDateLunchStrTime3} ${this.curDay.stageDay.lunchStrTime3}`).format();
				}

				let lunchEndTime3 = null;
				if (!_.isNil(this.curDay.stageDay.lunchEndTime3)) {
					lunchEndTime3 = moment(`${this.curDay.stageDay.startDateLunchEndTime3} ${this.curDay.stageDay.lunchEndTime3}`).format();
				}

				const comment = this.curDay.stageDay.comment;
				// console.log("before add:", this.curDay);

				if (
					this.checkDatesBeforeSave(
						strTime,
						endTime,
						actualEndTime,
						lunchStrTime,
						lunchEndTime,
						strTime2,
						endTime2,
						actualEndTime2,
						lunchStrTime2,
						lunchEndTime2,
						strTime3,
						endTime3,
						actualEndTime3,
						lunchStrTime3,
						lunchEndTime3,
						this.curDay.stageDay.is2NdShooting,
						this.curDay.stageDay.is3RdShooting
					)
				) {
					await addUpdStageDay(0, {
						date,
						strTime,
						endTime,
						lunchStrTime,
						lunchEndTime,
						actualEndTime,
						strTime2,
						endTime2,
						lunchStrTime2,
						lunchEndTime2,
						actualEndTime2,
						strTime3,
						endTime3,
						lunchStrTime3,
						lunchEndTime3,
						actualEndTime3,
						comment,
						useHours: this.curDay.stageDay.useHours,
						useMileageToSet: this.curDay.stageDay.useMileageToSet,
						kmToSetDescription: this.curDay.stageDay.kmToSetDescription,
						kmToSet: this.curDay.stageDay.kmToSet,
						kmToSetSalary: this.curDay.stageDay.kmToSetSalary,
						hoursDefined: this.curDay.stageDay.hoursDefined,
						kmToSetDefined: this.curDay.stageDay.kmToSetDefined,
						is2NdShooting: this.curDay.stageDay.is2NdShooting,
						is3RdShooting: this.curDay.stageDay.is3RdShooting
					});

					this.createToastForMobile(this.FormMSG(65, 'Success'), this.FormMSG(68, 'Stage day added successfully'), '', 'success');

					await this.reloadData();
				}
			} catch (error) {
				console.log(error);
				//this.erreur = error
				var myerrorObject = {
					err: ''
				};
				myerrorObject.err = error.message;
				this.erreur = myerrorObject;
			}
		},
		checkDatesBeforeSave(
			strTime,
			endTime,
			actualEndTime,
			lunchStrTime,
			lunchEndTime,
			strTime2,
			endTime2,
			actualEndTime2,
			lunchStrTime2,
			lunchEndTime2,
			strTime3,
			endTime3,
			actualEndTime3,
			lunchStrTime3,
			lunchEndTime3,
			is2NdShooting,
			is3RdShooting
		) {
			let result = true;

			// 1
			const STR_TIME = new Date(strTime).getTime();
			const END_TIME = new Date(endTime).getTime();
			const ACTUAL_END_TIME = new Date(actualEndTime).getTime();
			const LUNCH_STR_TIME = new Date(lunchStrTime).getTime();
			const LUNCH_END_TIME = new Date(lunchEndTime).getTime();

			if (STR_TIME >= END_TIME) {
				this.errorState.strTime = false;
				this.errorState.endTime = false;
				result = false;
			} else {
				this.errorState.strTime = null;
				this.errorState.endTime = null;
			}

			if (STR_TIME >= ACTUAL_END_TIME) {
				this.errorState.strTime = false;
				this.errorState.actualEndTime = false;
				result = false;
			} else {
				this.errorState.strTime = null;
				this.errorState.actualEndTime = null;
			}

			if (
				LUNCH_STR_TIME < LUNCH_END_TIME &&
				STR_TIME < LUNCH_STR_TIME &&
				STR_TIME < LUNCH_END_TIME &&
				END_TIME > LUNCH_STR_TIME &&
				END_TIME > LUNCH_END_TIME &&
				ACTUAL_END_TIME > LUNCH_STR_TIME &&
				ACTUAL_END_TIME > LUNCH_END_TIME
			) {
				this.errorState.lunchStrTime = null;
				this.errorState.lunchEndTime = null;
			} else {
				this.errorState.lunchStrTime = false;
				this.errorState.lunchEndTime = false;
				result = false;
			}

			if (is2NdShooting) {
				// 2
				const STR_TIME_2 = new Date(strTime2).getTime();
				const END_TIME_2 = new Date(endTime2).getTime();
				const ACTUAL_END_TIME_2 = new Date(actualEndTime2).getTime();
				const LUNCH_STR_TIME_2 = new Date(lunchStrTime2).getTime();
				const LUNCH_END_TIME_2 = new Date(lunchEndTime2).getTime();

				if (STR_TIME_2 >= END_TIME_2) {
					this.errorState.strTime2 = false;
					this.errorState.endTime2 = false;
					result = false;
				} else {
					this.errorState.strTime2 = null;
					this.errorState.endTime2 = null;
				}

				if (STR_TIME_2 >= ACTUAL_END_TIME_2) {
					this.errorState.strTime2 = false;
					this.errorState.actualEndTime2 = false;
					result = false;
				} else {
					this.errorState.strTime2 = null;
					this.errorState.actualEndTime2 = null;
				}

				if (!result) return result;

				if (
					LUNCH_STR_TIME_2 < LUNCH_END_TIME_2 &&
					STR_TIME_2 < LUNCH_STR_TIME_2 &&
					STR_TIME_2 < LUNCH_END_TIME_2 &&
					END_TIME_2 > LUNCH_STR_TIME_2 &&
					END_TIME_2 > LUNCH_END_TIME_2 &&
					ACTUAL_END_TIME_2 > LUNCH_STR_TIME_2 &&
					ACTUAL_END_TIME_2 > LUNCH_END_TIME_2
				) {
					this.errorState.lunchStrTime2 = null;
					this.errorState.lunchEndTime2 = null;
				} else {
					this.errorState.lunchStrTime2 = false;
					this.errorState.lunchEndTime2 = false;
					result = false;
				}

				if (!result) return result;
			}

			if (is3RdShooting) {
				// 3
				const STR_TIME_3 = new Date(strTime3).getTime();
				const END_TIME_3 = new Date(endTime3).getTime();
				const ACTUAL_END_TIME_3 = new Date(actualEndTime3).getTime();
				const LUNCH_STR_TIME_3 = new Date(lunchStrTime3).getTime();
				const LUNCH_END_TIME_3 = new Date(lunchEndTime3).getTime();

				if (STR_TIME_3 >= END_TIME_3) {
					this.errorState.strTime3 = false;
					this.errorState.endTime3 = false;
					result = false;
				} else {
					this.errorState.strTime3 = null;
					this.errorState.endTime3 = null;
				}

				if (STR_TIME_3 >= ACTUAL_END_TIME_3) {
					this.errorState.strTime3 = false;
					this.errorState.actualEndTime3 = false;
					result = false;
				} else {
					this.errorState.strTime3 = null;
					this.errorState.actualEndTime3 = null;
				}

				if (!result) return result;

				if (
					LUNCH_STR_TIME_3 < LUNCH_END_TIME_3 &&
					STR_TIME_3 < LUNCH_STR_TIME_3 &&
					STR_TIME_3 < LUNCH_END_TIME_3 &&
					END_TIME_3 > LUNCH_STR_TIME_3 &&
					END_TIME_3 > LUNCH_END_TIME_3 &&
					ACTUAL_END_TIME_3 > LUNCH_STR_TIME_3 &&
					ACTUAL_END_TIME_3 > LUNCH_END_TIME_3
				) {
					this.errorState.lunchStrTime3 = null;
					this.errorState.lunchEndTime3 = null;
				} else {
					this.errorState.lunchStrTime3 = false;
					this.errorState.lunchEndTime3 = false;
					result = false;
				}

				if (!result) return result;
			}

			return result;
		},
		updateTime(payload, field) {
			this.curDay.stageDay[field] = payload;
		},
		selectCurDay() {
			// check if curDay is in array of existing tsdays.
			// if yes, select it, if not, initialise a new curday with defaultvalues
			// look if chosen date is in tsdays, if yes get structure
			let foundCurDayInArray = false;
			//console.log("in selectCurDay");
			for (let i = 0; i < this.TsDays.length; i++) {
				let temp = this.TsDays[i].stageDay.date;
				temp = temp.substring(0, 10);
				//    console.log("temp:", temp);
				if (temp === this.curDay.stageDay.date) {
					// fill curDay with tsTays Data
					this.curDay = _.cloneDeep(this.TsDays[i]);

					this.initDateTime(this.curDay.stageDay, 'actualEndTime');
					this.initDateTime(this.curDay.stageDay, 'strTime');
					this.initDateTime(this.curDay.stageDay, 'endTime');

					this.initDateTime(this.curDay.stageDay, 'actualEndTime2');
					this.initDateTime(this.curDay.stageDay, 'strTime2');
					this.initDateTime(this.curDay.stageDay, 'endTime2');

					this.initDateTime(this.curDay.stageDay, 'actualEndTime3');
					this.initDateTime(this.curDay.stageDay, 'strTime3');
					this.initDateTime(this.curDay.stageDay, 'endTime3');

					this.initVariableMileageToSet();
					//    console.log("in update day found line:", this.curDay.date)
					foundCurDayInArray = true;
					break;
				}
			}

			if (foundCurDayInArray == false) {
				//    console.log("in selectCurDay not found in array");
				const tmpStr = this.curDay.stageDay.date;
				// this.curDay = _.omit(this.newCurDay, ['stageDay']);
				// this.curDay = _.assign(this.curDay, {
				// 	stageDay: _.cloneDeep(INIT_DATA_STAGE_DAY)
				// });
				this.curDay = _.cloneDeep(this.newCurDay);
				this.curDay.stageDay = _.cloneDeep(INIT_DATA_STAGE_DAY);
				this.curDay.stageDay.date = tmpStr;
				this.curDay.stageDay.startDateActualEndTime = tmpStr;
				this.curDay.stageDay.startDateEndTime = tmpStr;
				this.curDay.stageDay.startDateLunchEndTime = tmpStr;
				this.curDay.stageDay.startDateLunchStrTime = tmpStr;
				this.curDay.stageDay.startDateStrTime = tmpStr;

				console.log({ stageDaY: this.curDay.stageDay });
			}
		},
		validatedColor(validated) {
			return 'primary';
		},
		async updateDate($event) {
			// suspend link between tsdays and curday until we check if new one or existing one
			// this.curDay = _.omit(this.newCurDay, ['stageDay']);
			// this.curDay = _.assign(this.curDay, {
			// 	stageDay: _.cloneDeep(INIT_DATA_STAGE_DAY)
			// });
			this.curDay = _.cloneDeep(this.newCurDay);
			this.curDay.stageDay = _.cloneDeep(INIT_DATA_STAGE_DAY);

			//    console.log("curday after new", this.curDay.date);
			//console.log("event in updateDate", $event);
			// receiving 2019-02-24
			if (this.curDay.stageDay.date.startsWith('T')) {
				this.curDay.stageDay.date = $event + this.curDay.stageDay.date;
				// 1
				this.curDay.stageDay.startDateEndTime = $event + this.curDay.stageDay.date;
				this.curDay.stageDay.startDateActualEndTime = $event + this.curDay.stageDay.date;
				this.curDay.stageDay.startDateStrTime = $event + this.curDay.stageDay.date;
				this.curDay.stageDay.startDateLunchStrTime = $event + this.curDay.stageDay.date;
				this.curDay.stageDay.startDateLunchEndTime = $event + this.curDay.stageDay.date;
				this.endDateFirstShooting = $event + this.curDay.stageDay.date;
				// console.log('NEVER COMES HERE (start with T)', this.curDay.stageDay.date);
				// 2
				this.curDay.stageDay.startDateEndTime2 = $event + this.curDay.stageDay.date;
				this.curDay.stageDay.startDateActualEndTime2 = $event + this.curDay.stageDay.date;
				this.curDay.stageDay.startDateStrTime2 = $event + this.curDay.stageDay.date;
				this.curDay.stageDay.startDateLunchStrTime2 = $event + this.curDay.stageDay.date;
				this.curDay.stageDay.startDateLunchEndTime2 = $event + this.curDay.stageDay.date;
				this.endDateSecondShooting = $event + this.curDay.stageDay.date;
				// 3
				this.curDay.stageDay.startDateEndTime3 = $event + this.curDay.stageDay.date;
				this.curDay.stageDay.startDateActualEndTime3 = $event + this.curDay.stageDay.date;
				this.curDay.stageDay.startDateStrTime3 = $event + this.curDay.stageDay.date;
				this.curDay.stageDay.startDateLunchStrTime3 = $event + this.curDay.stageDay.date;
				this.curDay.stageDay.startDateLunchEndTime3 = $event + this.curDay.stageDay.date;
				this.endDateThirdShooting = $event + this.curDay.stageDay.date;
			} else {
				this.curDay.stageDay.date = $event;
				// 1
				this.curDay.stageDay.startDateEndTime = $event;
				this.curDay.stageDay.startDateActualEndTime = $event;
				this.curDay.stageDay.startDateStrTime = $event;
				this.curDay.stageDay.startDateLunchStrTime = $event;
				this.curDay.stageDay.startDateLunchEndTime = $event;
				this.endDateFirstShooting = $event;
				// 2
				this.curDay.stageDay.startDateEndTime2 = $event;
				this.curDay.stageDay.startDateActualEndTime2 = $event;
				this.curDay.stageDay.startDateStrTime2 = $event;
				this.curDay.stageDay.startDateLunchStrTime2 = $event;
				this.curDay.stageDay.startDateLunchEndTime2 = $event;
				this.endDateSecondShooting = $event;
				// 3
				this.curDay.stageDay.startDateEndTime3 = $event;
				this.curDay.stageDay.startDateActualEndTime3 = $event;
				this.curDay.stageDay.startDateStrTime3 = $event;
				this.curDay.stageDay.startDateLunchStrTime3 = $event;
				this.curDay.stageDay.startDateLunchEndTime3 = $event;
				this.endDateThirdShooting = $event;
			}
			// console.log("in updateDate curdaydate= ", this.curDay.date);

			// check if month changed
			const curMStemp = $event.substring(0, 7);

			if (this.curMonthStr === curMStemp) {
				// in same month select day

				this.selectCurDay();
			} else {
				// need to load new month
				//console.log("need to reload:" ,curMStemp);
				this.curMonthStr = curMStemp;
				await this.reloadData();
			}
		},
		async reloadData() {
			var StartDate = this.curMonthStr + '-01T00:00:00+00:00';
			let EndDate = moment(new Date(StartDate)).endOf('months');

			const result = await getStageDays(StartDate, EndDate);

			result.forEach(this.updateTZdataEach);
			this.TsDays = result;
			this.TsDaysTable = result;
			// select curDay in new data
			this.selectCurDay();
		},
		async delItem(id) {
			try {
				const action = async () => {
					let TsDayId = parseInt(id, 10);
					await delStageDay(TsDayId);

					await this.reloadData();
					this.createToastForMobile(this.FormMSG(65, 'Success'), this.FormMSG(66, 'Stage day deleted successfully'), '', 'success');
				};

				this.confirmModal(action, this.FormMSG(67, 'Are you sure ?'));
			} catch (e) {
				console.log(error);
				this.erreur = error;
			}
		},
		getRowCount(items) {
			//console.log(items)
			return items.length;
		},
		getMonthAndYear(string) {
			return string.substring(5, 7) + '/' + string.substring(0, 4);
		},
		rowClicked(item) {
			// copy item content into curDay structure.
			let temp;
			this.resetVariableMileageToSet();

			this.curDay = item;
			// for (const i in this.TsDays) {
			// 	temp = parseInt(this.TsDays[i].stageDay.id, 10);
			// 	if (temp === item.stageDay.id) {
			// 		// fill curDay with tsTays Data
			// 		this.curDay = _.cloneDeep(this.TsDays[i]);
			//
			// 		this.initDateTime(this.curDay.stageDay, 'actualEndTime');
			// 		this.initDateTime(this.curDay.stageDay, 'strTime');
			// 		this.initDateTime(this.curDay.stageDay, 'endTime');
			// 		this.initVariableMileageToSet();
			//
			// 		//console.log("rowclicked curday:", this.curDay);
			// 		if (this.rowSelected === i) {
			// 			// we reclick on the selected row, this will deselect it, so we force the reselection
			// 			this.clickRows(this.rowSelected);
			// 		}
			//
			// 		this.rowSelected = i;
			//
			// 		break;
			// 	}
			// }
		},
		initDateTime(stageDay, field) {
			if (stageDay[field] === '0001-01-01T00:00:00Z') {
				stageDay[field] = null;

				return true;
			}

			return false;
		},
		initVariableMileageToSet() {
			if (this.curDay.stageDay.kmToSetDefined) {
				this.valueMileageDefinition = this.curDay.stageDay.kmToSet;
				this.mileageDefinition = 'use_distance';
			}
			if (this.curDay.stageDay.hoursDefined) {
				this.valueMileageDefinition = this.curDay.stageDay.kmToSetSalary;
				this.mileageDefinition = 'use_fix_amount';
			}
		},
		resetVariableMileageToSet() {
			this.curDay = this.newCurDay;
			this.valueMileageDefinition = 0;
			this.mileageDefinition = 'use_distance';
		},
		handleChangeSetDefinition() {
			this.valueMileageDefinition = 0;
		}
	},
	async created() {
		if (this.curDay.stageDay.date.length === 0) {
			this.firstTimeInitialisation();
		}
		await this.reloadData();
	}
};
</script>
<style lang="scss">
.alert-info {
	color: #0c5460;
	background-color: #d1ecf1;
	border-color: #bee5eb;
}
</style>
